import React from "react"

// gatsby libraries
import { useStaticQuery, graphql } from "gatsby"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"

// @fortawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClipboardList,
  faEnvelopeOpenText,
} from "@fortawesome/pro-solid-svg-icons"

// page styles
import hotPepperEatingDocumentsStyle from "assets/jss/material-kit-pro-react/views/hotPepperEatingContestPageSections/hotPepperEatingContestDocumentsStyle.jsx"
const useStyles = makeStyles(hotPepperEatingDocumentsStyle)

export default function HotPepperEatingContestDocuments() {
  const {
    hotPepperEatingContestRules,
    hotPepperEatingContestWaiver,
  } = useStaticQuery(graphql`
    query hotPepperEatingContestDocumentsImages {
      hotPepperEatingContestRules: file(
        relativePath: {
          eq: "hot-pepper-eating-contest/documents/hot-pepper-eating-contest-official-rules.pdf"
        }
      ) {
        publicURL
        name
      }
      hotPepperEatingContestWaiver: file(
        relativePath: {
          eq: "hot-pepper-eating-contest/documents/hot-pepper-eating-contest-waiver.pdf"
        }
      ) {
        publicURL
        name
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={10} md={8}>
            <h2 className={classes.title}>Download Contest Information</h2>
            <p className={classes.description}>
              Below is a downloadable copy of the Scoville Showdown rules as
              well as a Release and Waiver of Liability Form. Please print and
              sign this form to bring with you the day of the competition.
            </p>
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center">
          <GridItem
            md={4}
            sm={4}
            xs={10}
            className={classes.hotPepperEatingDocumentsItem}
          >
            <FontAwesomeIcon
              className={classes.documentIcons}
              icon={faClipboardList}
            />
            <h4 className={classes.subtitle}>Scoville Showdown Rules</h4>
            <a href={hotPepperEatingContestRules.publicURL}>
              <Button
                className={classes.hotPepperEatingDocumentsButton}
                color="primary"
              >
                <PictureAsPdfIcon />
                Download
              </Button>
            </a>
          </GridItem>
          <GridItem
            md={4}
            sm={4}
            xs={10}
            className={classes.hotPepperEatingDocumentsItem}
          >
            <FontAwesomeIcon
              className={classes.documentIcons}
              icon={faEnvelopeOpenText}
            />
            <h4 className={classes.subtitle}>Liability Waiver</h4>
            <a href={hotPepperEatingContestWaiver.publicURL}>
              <Button
                className={classes.hotPepperEatingDocumentsButtonBottom}
                color="primary"
              >
                <PictureAsPdfIcon />
                Download
              </Button>
            </a>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
