import {
  container,
  description,
  section,
  textCenter,
  title,
} from "assets/jss/material-kit-pro-react.jsx"

const hotPepperEatingContestDocumentsStyle = (theme) => ({
  container: {
    ...container,
    marginBottom: "30px",
  },
  contestsImage: {
    maxHeight: "300px",
    borderRadius: "6px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  description: {
    ...description,
    ...textCenter,
  },
  hotPepperEatingDocumentsButton: {
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "2rem",
    },
  },
  hotPepperEatingDocumentsButtonBottom: {
    margin: "0 auto",
  },
  documentIcons: {
    margin: "0 auto",
    fontSize: "1.5rem",
  },
  hotPepperEatingDocumentsItem: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  section: {
    ...section,
    padding: "70px 0px 0px 0px",
  },
  subtitle: {
    ...title,
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "15px",
  },
  title: {
    ...title,
    textAlign: "center",
  },
})

export default hotPepperEatingContestDocumentsStyle
